import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { AlertTitle, Box, Skeleton, Tab, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { PhoenixBaseCard, PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { useTabs } from '../../../hooks/useTabs'
import { AffectedRating, RatingsImpactedFromModifyCoverageResponse, RatingsImpactedResponse } from '../../../models/CoverageMovement'

const columns: MRT_ColumnDef<AffectedRating>[] = [
  { accessorKey: 'ratingEffectiveDate', header: 'Rating Effective Date' },
  {
    accessorKey: 'modFactor',
    header: 'Mod Factor',
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => (
      // eslint-disable-next-line react/prop-types
      <PhoenixLink to={`/ratings/${row.original.ratingGuid}`} value={row.original.modFactor} openInNewTab />
    ),
  },
  { accessorKey: 'statusDate', header: 'Status Date' },
]

interface RatingsImpactedSectionProps {
  ratingsImpacted: RatingsImpactedFromModifyCoverageResponse[],
  isLoading?: boolean
}

const isRatingsImpactedEmpty = (ratingsImpacted: RatingsImpactedFromModifyCoverageResponse[]) => {
  return ratingsImpacted.length === 0 || ratingsImpacted.every(ratingImpacted => ratingImpacted.affectedRatings.length === 0)
}

const RatingsImpactedSection = ({
  ratingsImpacted,
  isLoading
}: RatingsImpactedSectionProps) => {
  const { tabIndex, handleTabChange } = useTabs()
  return (
    <PhoenixBaseCard cardTitle="Ratings Impacted" variantType="Secondary" defaultWidth={12} isLoading={isLoading}>
      {!isRatingsImpactedEmpty(ratingsImpacted) &&
      <>
      <TabContext value={tabIndex}>
       <RatingsImpactedTabs ratingsImpacted={ratingsImpacted} handleTabChange={handleTabChange} />
      </TabContext>
      
      <Alert severity={'info'} sx={{ width: '100%', mt: 2 }}>
        <AlertTitle>Combinability changes will impact ratings.</AlertTitle>
        <Typography variant="body2">Click CONFIRM CHANGES to apply.</Typography>
      </Alert>
      </>}
    </PhoenixBaseCard>
  )
}

const RatingsImpactedTabs = ({
    ratingsImpacted,
    handleTabChange
}: {ratingsImpacted: RatingsImpactedFromModifyCoverageResponse[], handleTabChange:(_: React.SyntheticEvent, newValue: string) => void})=> {
    return (
        <>
            <TabList onChange={handleTabChange}>
                {ratingsImpacted.map((ratingImpacted, index) => (
                    <Tab  key={index} label={`COMBO ${ratingImpacted.comboID}`} value={index.toString()} />
                )
                )}
            </TabList>
            {ratingsImpacted.map((ratingsImpacted, index) => (
            <TabPanel key={index} value={index.toString()} sx={{ padding: 0, width: '100%' }}>
                <Box>
                    <PhoenixBaseTable columns={columns} data={ratingsImpacted?.affectedRatings} />
                </Box>
            </TabPanel>
            ))}
       </>
    )
}

export default RatingsImpactedSection
