import { ComboGroupMembership } from '../models/ComboGroupMembership'
import { ComboIdResponse } from '../models/ComboIdResponse'
import { ControllingCoverageOptions } from '../models/ControllingCoverageOptions'
import { ComboCoverageResponse } from '../models/CoverageGroupModels'
import { CoverageGroupOwnershipModel } from '../models/CoverageGroupOwnershipModel'
import { CoverageGroupProfileResponse } from '../models/CoverageGroupProfileResponse'
import {
  MoveCoverageRequest,
  MoveCoverageResponse,
  RatingsImpactedFromModifyCoverageResponse,
  RatingsImpactedRequest,
  RatingsImpactedResponse,
} from '../models/CoverageMovement'
import { SearchResponse } from '../models/SearchResponse'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getComboDataByCoverageId = async (coverageId: string): Promise<ComboIdResponse> => {
  const response: ApiResponse<ComboIdResponse> = await api.get(`${Endpoints.ComboId}/${coverageId}/combo`)
  return response.data
}

export const getCoverageDataByCoverageId = async (
  comboId: string,
  primaryCoverageId?: string
): Promise<ComboCoverageResponse> => {
  const response: ApiResponse<ComboCoverageResponse> = await api.get(`${Endpoints.Combos}/${comboId}/coverages`, {
    params: {
      primary: primaryCoverageId,
    },
  })
  return response.data
}

export const searchByFEIN = async (fein: string): Promise<SearchResponse> => {
  const response: ApiResponse<SearchResponse> = await api.get(`${Endpoints.Coverages}/SearchByFein/${fein}`)
  return response.data
}

export const searchByName = async (name: string, max?: number): Promise<SearchResponse> => {
  let ep = `${Endpoints.Coverages}/SearchByName/${name}`
  if (max) {
    ep = `${ep}?max=${max}`
  }
  const response: ApiResponse<SearchResponse> = await api.get(`${ep}`)
  return response.data
}

export const getControllingCoverageOptions = async (
  coverageId: string,
  originComboGuid: string,
  destinationComboGuid?: string
) => {
  const response: ApiResponse<ControllingCoverageOptions> = await api.get(
    `${Endpoints.Coverages}/${Number(coverageId)}/move/controlling-choices`,
    {
      params: {
        originComboGuid,
        destinationComboGuid,
      },
    }
  )
  return response.data
}

export const getRatingsImpacted = async (coverageId: string, ratingsImpactedRequest: RatingsImpactedRequest) => {
  const response: ApiResponse<RatingsImpactedResponse> = await api.get(
    `${Endpoints.Coverages}/${Number(coverageId)}/move/affected-ratings`,
    {
      params: ratingsImpactedRequest,
    }
  )
  return response.data
}

export const moveCoverageToExistingCombo = async (coverageId: string, moveCoverageRequest: MoveCoverageRequest) => {
  const response: ApiResponse<MoveCoverageResponse> = await api.patch(
    `${Endpoints.Coverages}/${Number(coverageId)}/move`,
    moveCoverageRequest
  )
  return response.data
}

export const moveCoverageToNewCombo = async (coverageId: string, moveCoverageRequest: MoveCoverageRequest) => {
  const response: ApiResponse<MoveCoverageResponse> = await api.post(
    `${Endpoints.Coverages}/${Number(coverageId)}/move`,
    moveCoverageRequest
  )
  return response.data
}

export const getComboMembershipHistory = async (coverageId: string) => {
  const response: ApiResponse<ComboGroupMembership[]> = await api.get(
    `${Endpoints.Coverages}/${Number(coverageId)}/comboGroupMembershipHistory`
  )
  return response.data
}

export const getCoverageGroupProfile = async (coverageId: string) => {
  const response: ApiResponse<CoverageGroupProfileResponse> = await api.get(
    `${Endpoints.Coverages}/${coverageId}/profile`
  )

  return response.data
}

export const getCoverageGroupOwnership = async (coverageId: string) => {
  const response: ApiResponse<CoverageGroupOwnershipModel[]> = await api.get(
    `${Endpoints.Coverages}/${Number(coverageId)}/ownership`
  )
  return response.data
}

export const getAffectedRatingForModifyDetails = async (coverageId: string, newIncludeDate: string) => {
  const response: ApiResponse<RatingsImpactedFromModifyCoverageResponse[]> = await api.get(
    `${Endpoints.Coverages}/${Number(coverageId)}/modify-details/affected-ratings`,
    {
      params: { includeDate: newIncludeDate },
    }
  )
  return response.data
}
