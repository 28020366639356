import { CalendarToday } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Stack } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import dayjs, { Dayjs, isDayjs } from 'dayjs'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useGetRatingsImpactedForModifyCoverageIncludeDate } from '../../../cache/coverageCache'
import { CombinedCoverageHistoryModel } from '../../../models/CombinedCoverageHistory'
import { handleTitleCase } from '../../../utils/handleTitleCase'
import RatingsImpactedSection from './RatingsImpactedSection'

interface ModifyCoverageCardProps {
  sourceCoverageData?: CombinedCoverageHistoryModel
  oldestIncludeDate?: Dayjs | string
  onCancel: () => void
  isLoading?: boolean
}
interface IModifyCoverageFormProps {
  newIncludeDate: Dayjs | string
}

export const ModifyCoverageCard = ({
  sourceCoverageData,
  onCancel,
  oldestIncludeDate,
  isLoading,
}: ModifyCoverageCardProps) => {
  const formMethods = useForm<IModifyCoverageFormProps>({
    defaultValues: {
      newIncludeDate: '',
    },
    mode: 'all',
  })
  const { control, handleSubmit, formState, watch } = formMethods
  const [newIncludeDateConfirmed, setNewIncludeDateConfirmed] = useState(false)
  const newIncludeDate = watch('newIncludeDate')
  const minDate = oldestIncludeDate ? dayjs(oldestIncludeDate) : dayjs(sourceCoverageData?.includeDate)
  const coverageId = sourceCoverageData?.coverageId.toString() ?? ''

  //TODO STORY: 3849
/* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    data: affectedRatingsData,
    refetch: fetchAffectedRatings,
    isLoading: isAffectedRatingsLoading,
    isError: isAffectedRatingsError,
    error: affectedRatingsError,
  } = useGetRatingsImpactedForModifyCoverageIncludeDate(
    coverageId,
    isDayjs(newIncludeDate) ? newIncludeDate.format('YYYY-MM-DD') : ''

  )

  const confirmNewIncludeDate = async () => {
    setNewIncludeDateConfirmed(true)
    fetchAffectedRatings();
    }

  const onSubmit = async () => {
    if (!newIncludeDateConfirmed) {
      confirmNewIncludeDate();
    } else {
        //TODO Set Controlling Coverage
        // await updateCoverageIncludeDate(coverageId, newIncludeDate)
    }
  }
 
  return (
    <PhoenixBaseCard cardTitle={'Modify Coverage'} contentPadded>
      <Box width={'100%'}>
        <Stack direction={'row'} spacing={2} sx={{ m: 2 }}>
          <FieldGroup
            label={'Include Date'}
            value={sourceCoverageData?.includeDate}
            icon={<CalendarToday />}
            isLoading={isLoading ?? false}
          />
          <FieldGroup
            label={'Exclude Date'}
            value={sourceCoverageData?.excludeDate}
            icon={<CalendarToday />}
            isLoading={isLoading ?? false}
          />
          <FieldGroup
            label={'Controlling Start Date'}
            value={handleTitleCase(sourceCoverageData?.controllingStartDate)}
            icon={<CalendarToday />}
            isLoading={isLoading ?? false}
          />
          <FieldGroup
            label={'Controlling End Date'}
            value={sourceCoverageData?.controllingEndDate}
            isLoading={isLoading ?? false}
            icon={<CalendarToday />}
          />
        </Stack>
      </Box>
      <Box width={'100%'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PhoenixBaseCard cardTitle={'Update'} variantType="Secondary" contentPadded>
            {!newIncludeDateConfirmed && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="newIncludeDate"
                  control={control}
                  rules={{
                    required: 'New Include Date is required',
                    validate: (date: Dayjs | string) => {
                      if (isDayjs(date)) {
                        if (!date.isValid()) {
                          return 'Invalid Date'
                        }
                        if (date.diff(minDate, 'day') <= 0) {
                          return `New Include Date must occur after include date for previous coverage combination of: ${minDate.format('MM/DD/YYYY')}.`
                        }
                        if (date.diff(dayjs(), 'day') >= 365) {
                          return 'New Include Date must not exceed 12 months from current date.'
                        }
                      }
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Grid container p={1}>
                      <Grid item xs={2}>
                        <DatePicker
                          sx={{ m: 2 }}
                          label="New Include Date"
                          shouldDisableDate={(date: Dayjs) => {
                            return date.diff(minDate, 'day') <= 0 || date.diff(dayjs(), 'day') >= 365
                          }}
                          onChange={(date) => field.onChange(date)}
                          slotProps={{
                            textField: {
                              value: field.value,
                              size: 'small',
                              fullWidth: true,
                              error: fieldState.invalid,
                              helperText: fieldState.error?.message,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            )}
            {newIncludeDateConfirmed && (
              <Box sx={{ m: 2 }}>
                <FieldGroup
                  label={'New Include Date'}
                  value={isDayjs(newIncludeDate) ? newIncludeDate?.format('MM/DD/YYYY') : ''}
                />
              </Box>
            )}
          </PhoenixBaseCard>

          {newIncludeDateConfirmed && (
            <>
               
                <RatingsImpactedSection ratingsImpacted={affectedRatingsData} isLoading={isAffectedRatingsLoading}/>
                
           </>
          )}
          <Stack direction="row" spacing={2} sx={{ m: 2, justifyContent: 'end' }}>
            <Button color="primary" onClick={onCancel}>
              Back To Combo
            </Button>
            {newIncludeDateConfirmed && (
              <Button color="primary" onClick={() => setNewIncludeDateConfirmed(false)}>
                Prev
              </Button>
            )}
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={formState.isSubmitting}
              disabled={!formState.isDirty || !formState.isValid}
            >
              {!newIncludeDateConfirmed ? 'Next' : 'Confirm Changes'}
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </PhoenixBaseCard>
  )
}
